import { App, inject, InjectionKey, Ref, ref } from "vue";
import { addMediaQueryListener, removeMediaQueryListener } from "@/shared/plugin/device-detect/device-detect.helper";
import { useTNLifeBridge } from "@/shared/composables/use-tnlife-bridge/use-tnlife-bridge";

const changeEvent = "change";

interface DeviceDetect {
  isMobile: Ref<boolean>;
  isLaptop: Ref<boolean>;
  isDesktop: Ref<boolean>;
}
const DeviceInjectKey: InjectionKey<DeviceDetect> = Symbol("DeviceInjectKey");

export default {
  install: (app: App): void => {
    const { isApp } = useTNLifeBridge();
    const mobileWidth = 320 - (isApp() ? 72 : 0);
    const laptopWidth = 1008 - (isApp() ? 72 : 0);
    const desktopWidth = 1280 - (isApp() ? 72 : 0);

    const mediaMobile = matchMedia(`(min-width: ${mobileWidth}px) and (max-width: ${laptopWidth - 1}px)`);
    const mediaLaptop = matchMedia(`(min-width: ${laptopWidth}px) and (max-width: ${desktopWidth - 1}px)`);
    const mediaDesktop = matchMedia(`(min-width: ${desktopWidth}px)`);
    const isMobile = ref(mediaMobile.matches);
    const isLaptop = ref(mediaLaptop.matches);
    const isDesktop = ref(mediaDesktop.matches);
    const setLayout = () => {
      isMobile.value = mediaMobile.matches;
      isLaptop.value = mediaLaptop.matches;
      isDesktop.value = mediaDesktop.matches;
    };
    addMediaQueryListener(mediaMobile, changeEvent, setLayout);
    addMediaQueryListener(mediaLaptop, changeEvent, setLayout);
    addMediaQueryListener(mediaDesktop, changeEvent, setLayout);
    const unmount = app.unmount.bind(app);
    app.unmount = () => {
      removeMediaQueryListener(mediaMobile, changeEvent, setLayout);
      removeMediaQueryListener(mediaLaptop, changeEvent, setLayout);
      removeMediaQueryListener(mediaDesktop, changeEvent, setLayout);
      unmount();
    };
    app.provide("isMobile", isMobile);
    app.provide("isLaptop", isLaptop);
    app.provide("isDesktop", isDesktop);
    app.provide(DeviceInjectKey, {
      isMobile,
      isLaptop,
      isDesktop,
    });
  },
};

export const useDeviceDetect = () => {
  const DeviceDetect = inject<DeviceDetect>(DeviceInjectKey);
  if (DeviceDetect !== undefined) {
    return DeviceDetect;
  } else {
    throw Error("DeviceDetect not define");
  }
};
