import { RpcInterceptor } from "@protobuf-ts/runtime-rpc/build/types/rpc-interceptor";
import { type RpcOptions, ServiceInfo, UnaryCall } from "@protobuf-ts/runtime-rpc";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const mockStore: Record<string, Record<string, (i: any) => any>> = {};

type GrpcMethodResponse<ClientT extends ServiceInfo, Method extends keyof ClientT> = ClientT[Method] extends (
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  input: any,
  options?: RpcOptions,
) => { response: Promise<infer R> }
  ? R
  : never;

type GrpcMethodRequest<ClientT extends ServiceInfo, Method extends keyof ClientT> = ClientT[Method] extends (
  input: infer R,
  options?: RpcOptions,
) => any
  ? R
  : never;

type MockCallback<ClientT extends ServiceInfo, Method extends keyof ClientT> = (
  input: GrpcMethodRequest<ClientT, Method>,
) => GrpcMethodResponse<ClientT, Method>;

export const defineMock = <ClientT extends ServiceInfo, Method extends keyof ClientT>(
  client: ClientT,
  method: Method,
  callback: MockCallback<ClientT, Method>,
) => {
  if (process.env.NODE_ENV === "development") {
    if (mockStore[client.typeName] === undefined) {
      mockStore[client.typeName] = {};
    }
    mockStore[client.typeName][method as string] = callback;
  }
};

export const grpcMockInterceptor: RpcInterceptor = {
  interceptUnary: (next, method, input, options) => {
    const callBack =
      mockStore[method.service.typeName] && mockStore[method.service.typeName][method.localName] !== undefined
        ? mockStore[method.service.typeName][method.localName]
        : undefined;
    if (callBack !== undefined) {
      return new UnaryCall(
        method,
        {
          isMock: "true",
        },
        input,
        Promise.resolve({}),
        new Promise((resolve) => setTimeout(() => resolve(callBack(input)), 1500)),
        Promise.resolve({ code: "200", detail: "" }),
        Promise.resolve({}),
      );
    }
    return next(method, input, options);
  },
};
