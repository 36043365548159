import { NavigationItem } from "@/shared/helper/navigation/navigation.types";
import { useAcl } from "vue-simple-acl/src";
import { AclRole } from "@/shared/plugin/acl/acl.type";

const { anyRole } = useAcl();

export const getFilteredNavListByRole = (list: NavigationItem[]): NavigationItem[] =>
  list.filter(({ showFor }: NavigationItem) => anyRole(showFor));

export const allRoles: AclRole[] = [
  AclRole.User,
  AclRole.ApprovingAgent,
  AclRole.Accountant,
  AclRole.Director,
  AclRole.Admin,
  AclRole.Support,
  AclRole.DmsAdmin,
  AclRole.DmsListAdmin,
  AclRole.HrPersonnel,
];
